<template>
  <v-form ref="form" v-model="formValidation" lazy-validation @submit.prevent="handleFormSubmit">
    <v-row>
      <v-col cols="12">
        <v-text-field
          type="text"
          label="İstifadəçi adı"
          filled
          dense
          v-model="formData.username"
          :rules="formRules.username"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Şifrə"
          filled
          dense
          v-model="formData.password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :rules="formRules.password"
          required
          :append-icon="passwordInputIcon"
          @click:append="togglePasswordVisibility"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn type="submit" color="primary" depressed block large :loading="isFormSubmitting">
          Daxil ol
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    isFormSubmitting: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isPasswordVisible: false,
      formValidation: false,
      formData: {
        username: '',
        password: ''
      },
      formRules: {
        username: [v => !!v || 'İstifadəçi adını qeyd edin'],
        password: [v => !!v || 'Şifrəni qeyd edin']
      }
    }
  },
  computed: {
    passwordInputIcon() {
      return this.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.$emit('formSubmit', this.formData)
      }
    }
  }
}
</script>
